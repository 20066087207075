import axios from "axios";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

export default class BlogEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      qState: "",
      authorname: "",
      title: "",
      channel: [],
      selectedChannel: "",
      slug: "",
      id: "",
      featuredImage: "",
      startDate: new Date(),
      schedule_date: new Date(),
      metaDescription: "",
      metaKeywords: "",
      authorError: "",
      slugError: "",
      titleError: "",
      Error: "",
      metaDescriptionError: "",
      metakeywordsError: "",
      tags: "",
      selectedChannelId: -1,
    };
  }

  handleChange = (value) => {
    console.log(value);
    this.setState({ qState: value });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  handleSubmit() {
    const content = this.state.qState;
    const AuthorsName = this.state.authorname;
    const Title = this.state.title;
    const Slug = this.state.slug;

    const channel = this.state.selectedChannel;
    const id = this.state.id;
    const featuredImage = this.state.featuredImage;

    const token = localStorage.getItem("token");

    const payload = {
      title: Title,
      slug: Slug,
      content: content,
      author: AuthorsName,
      created_by: {
        key: "authapicheck123",
      },
      updated_by: null,
      channels: channel.id,
      created_at: this.state.startDate,
      published_at: this.state.schedule_date,
      meta_description: this.state.metaDescription,
      meta_keyword: this.state.metaKeywords,
      tag: this.state.tags,
    };
    console.log(channel);

    if (featuredImage !== "") payload.img_path = featuredImage;

    axios
      .put(
        process.env.REACT_APP_BLOG_API_BASE_URL +
          "/blog/api/v1/admin/update_blog/" +
          this.state.blog_id,
        payload,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        toast("Blog updated successfully !!");
        this.setState({
          slugError: "",
          titleError: "",
          authorError: "",
          metaDescriptionError: "",
        });
        this.props.history.push("/blogs");
      })
      .catch(
        function (error) {
          // handle error
          console.log(error);
          if (error.response) {
            this.authorizationCheck(error.response.status);
          }

          if (error.response.data["author"]) {
            this.setState({
              authorError: error.response.data["author"],
            });
            toast.error(<div>Author: {this.state.authorError}</div>);
          } else {
            this.setState({
              authorError: "",
            });
          }
          if (error.response.data["slug"]) {
            this.setState({
              slugError: error.response.data["slug"],
            });
            toast.error(<div>Slug: {this.state.slugError}</div>);
          } else {
            this.setState({
              slugError: "",
            });
          }
          if (error.response.data["title"]) {
            this.setState({
              titleError: error.response.data["title"],
            });
            toast.error(<div>Title: {this.state.titleError}</div>);
          } else {
            this.setState({
              titleError: "",
            });
          }

          if (error.response.data["meta_description"]) {
            this.setState({
              metadescriptionError: error.response.data["meta_description"],
            });
            toast.error(<div>Meta Description: {this.state.metadescriptionError}</div>);
          } else {
            this.setState({
              metadescriptionError: "",
            });
          }
        }.bind(this)
      );
    this.props.history.push("/blogs");
  }

  // ====================================================================================
  componentDidMount() {
    const { slug } = this.props.match.params;

    axios.get(`https://api.evidentbd.com/blog/api/v1/public/blogs?slug=${slug}`).then((res) => {
      const { data } = res;

      console.log(data);

      this.setState({
        title: data.data[0].title,
        authorname: data.data[0].author,
        slug: data.data[0].slug,
        metaDescription: data.data[0].meta_description,
        selectedChannel: data.data[0].channels,
        metaKeywords: data.data[0].meta_keyword,
        startDate: new Date(data.data[0].created_at),
        tags: data.data[0].tag,
        qState: data.data[0].content,
        schedule_date: new Date(data.data[0].published_at),
        blog_id: data.data[0].id,
      });
    });

    const token = localStorage.getItem("token");
    axios
      .get(process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/channels", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // console.log(res.data)
        this.setState({ channel: res.data.data });
        this.setState({ selectedChannel: res.data[0].id });
        //console.log("channels", res.data[0].id)
      })
      .catch(
        function (error) {
          // handle error
          if (error.response) {
            this.authorizationCheck(error.response.status);
          }
        }.bind(this)
      );
  }

  authorizationCheck(status) {
    if (status === 401) {
      axios
        .post(
          process.env.REACT_APP_AUTH_BASE_URL + "/api/v1/auth/refresh-token/",
          {
            refresh_token: localStorage.getItem("refresh_token"),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          localStorage.setItem("token", res.data.data.access_token);
          localStorage.setItem("refresh_token", res.data.data.refresh_token);
        })
        .catch(
          function (error) {
            // handle error
            localStorage.clear();
            this.props.history.push("/");
          }.bind(this)
        );
    }
  }

  handleFeaturedImageChange = async (event) => {
    async function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
    let image = await getBase64(event.target.files[0]);
    // console.log(image);

    const token = localStorage.getItem("token");
    let image_response = await axios.post(
      "https://transmitter.evidentbd.com/api/v1/upload/image",
      {
        service: "evident-blog",
        encoded_image: image,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
          // 'Content-Type': 'application/json'
        },
      }
    );

    const image_url = image_response.data.data.edge_url;
    //console.log(image_url);

    this.setState({
      featuredImage: image_url,
    });

    //console.log(this.state.featuredImage);
  };

  handleDropdownChange = ({ target }) => {
    this.setState({
      selectedChannel: target.value,
    });

    //console.log(this.state.selectedChannel)
  };

  async uploadImageCallBack(file) {
    async function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
    let image = await getBase64(file);

    return new Promise(async (resolve, reject) => {
      const token = localStorage.getItem("token");

      //let response = null;

      let image_response = await axios.post(
        "https://transmitter.evidentbd.com/api/v1/upload/image",
        {
          service: "evident-blog",
          encoded_image: image,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            // 'Content-Type': 'application/json'
          },
        }
      );

      const image_url = image_response.data.data.edge_url;
      // console.log(image_url);
      resolve({ data: { link: image_url } });
    });
  }

  render() {
    return (
      <div className="container mt-3 mb-6">
        <div className="grid lg:grid-cols-2 gap-4 mt-4">
          <nav className="bg-grey-light rounded font-sans w-full pl-1">
            <ol className="list-reset flex text-gray-500">
              <li>
                <Link to="/dashboard">
                  <span className="hover:text-gray-800">Dashboard</span>
                </Link>
              </li>
              <span className="mx-2">/</span>
              <li>
                <Link to="/blogs">
                  <span className="hover:text-gray-800">Blogs</span>
                </Link>
              </li>
              <span className="mx-2">/</span>
              <li>Edit Blog</li>
            </ol>
          </nav>
        </div>

        <h1 className="text-xl font-bold leading-tight flex items-center mb-5 pl-1 mt-5">
          Edit Blog
        </h1>

        <div className="h-3/4 container editor bg-white p-5 rounded rounded-md">
          <div className="grid lg:grid-cols-2 gap-4 mt-4 mb-4">
            <div className="w-full">
              <label className="uppercase text-gray-400 text-xs">Title</label>
              <input
                title="Title of the Blog"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Title of the Blog"
                value={this.state.title}
                // onChange={handleChange}
                onChange={(event) => {
                  const Title = event.target.value;
                  this.setState({ title: Title });
                }}
              />
              {this.state.titleError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.titleError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>

            <div className="w-full">
              <label className="uppercase text-gray-400 text-xs">Slug</label>
              <input
                title="Slug"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Slug"
                maxLength="50"
                value={this.state.slug}
                // onChange={handleChange}
                onChange={(event) => {
                  const Slug = event.target.value;
                  this.setState({ slug: Slug });
                }}
              />
              {this.state.slugError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.slugError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>
          </div>

          <div className="grid lg:grid-cols-3 gap-4 mt-4 mb-4">
            <div className="w-full">
              <input
                title="Meta Description"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Meta Description"
                maxLength="155"
                value={this.state.metaDescription}
                // onChange={handleChange}
                onChange={(event) => {
                  const metaDescription = event.target.value;
                  this.setState({ metaDescription: metaDescription });
                }}
              />
              {this.state.metadescriptionError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.metadescriptionError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>
            <div className="w-full">
              <input
                title="Meta Keywords"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Meta Keywords"
                maxLength="100"
                value={this.state.metaKeywords}
                // onChange={handleChange}
                onChange={(event) => {
                  const metaKeywords = event.target.value;
                  this.setState({ metaKeywords: metaKeywords });
                }}
              />
              {this.state.metakeywordsError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.metakeywordsError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>
            <div className="w-full">
              <input
                title="Input Tags"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Input Tags"
                maxLength="100"
                value={this.state.tags}
                onChange={(event) => {
                  const tags = event.target.value;
                  this.setState({ tags: tags });
                }}
              />
              {this.state.metakeywordsError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.metakeywordsError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>
          </div>

          <div className="grid lg:grid-cols-4 gap-4 mt-4 mb-4">
            <div className="w-full">
              <input
                title="Author's Name"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Author's Name"
                value={this.state.authorname}
                onChange={(event) => {
                  const AuthorsName = event.target.value;

                  this.setState({ authorname: AuthorsName });
                }}
              />
              {this.state.authorError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.authorError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>

            <div className="z-50 grid lg:grid-cols-1">
              {/* <label className="uppercase text-gray-400 text-xs">Publish Date</label> */}
              <DatePicker
                title="Publish Date"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                selected={this.state.startDate}
                onChange={(date) => {
                  this.setState({ startDate: date });
                }}
                isClearable
                placeholderText="Publish Date"
              />
            </div>

            <div className="w-full">
              <select
                title="Select Channel"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                type="button"
                onChange={this.handleDropdownChange}
              >
                {this.state?.channel
                  .filter(({ id }) => id === this.state.selectedChannel?.id)
                  .map(({ name, id }, index) => (
                    <option
                      className="py-1"
                      value={id}
                      key={id}
                      selected={id === this.state.selectedChannel.id}
                    >
                      {name}
                    </option>
                  ))}
              </select>
              <label className="text-red-500 text-sm"></label>
            </div>

            <div className="w-full">
              <input
                type="file"
                title="Feature Picture"
                className="w-full bg-white py-2 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Feature Picture"
                name="featuredImage"
                onChange={this.handleFeaturedImageChange}
              />
            </div>
          </div>

          <div className="grid lg:grid-cols-1 gap-4 mt-4 mb-4">
            {/* quill editor */}
            <div className="text-editor" style={{}}>
              <EditorToolbar />
              <ReactQuill
                style={{ height: "80vh" }}
                theme="snow"
                value={this.state.qState}
                onChange={this.handleChange}
                placeholder={"Write something awesome..."}
                modules={modules}
                formats={formats}
              />
            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 gap-4 mt-4 mb-4">
          <div className="z-50 px-5 py-2">
            <label className="text-gray-700 text-sm">Schedule Date: </label>
            <DatePicker
              type="button"
              title="Select Schedule Date"
              className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
              selected={this.state.schedule_date}
              onChange={(date) => this.setState({ schedule_date: date })}
              popperClassName="z-50"
              isClearable
              wrapperClassName="ml-3 "
              placeholderText="Schedule Publish Date"
            />
          </div>

          <div className="px-5 py-2 flex justify-end">
            <button
              type="submit"
              className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-24 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              onClick={() => {
                this.handleSubmit();
              }}
            >
              Update
            </button>
          </div>
        </div>

        <div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}
