import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

export default class BlogCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: true,
      qState: "",
      authorname: "",
      title: "",
      channel: [],
      selectedChannel: "",
      slug: "",
      featuredImage: null,
      startDate: new Date(),
      schedule_date: null,
      metaDescription: "",
      metaKeywords: "",
      authorError: "",
      slugError: "",
      titleError: "",
      Error: "",
      ContentError: "",
      metadescriptionError: "",
      metakeywordsError: "",
      tags: "",
    };
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
  }

  handleChange = (value) => {
    console.log(value);
    this.setState({ qState: value });
  };

  handleSubmit() {
    const content = this.state.qState;
    const AuthorsName = this.state.authorname;
    const Title = this.state.title;
    const Slug = this.state.slug;

    const channel = this.state.selectedChannel;
    const token = localStorage.getItem("token");
    const featured = this.state.featuredImage;
    const tags = this.state.tags;

    console.log(featured);

    axios
      .post(
        process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/add_blog",
        {
          title: Title,
          slug: Slug,
          content: content,
          author: AuthorsName,
          img_path: featured,
          created_by: {
            key: "authapicheck123",
          },
          updated_by: null,
          channels: channel,
          created_at: this.state.startDate,
          published_at: this.state.schedule_date,
          meta_description: this.state.metaDescription,
          meta_keyword: this.state.metaKeywords,
          tag: tags,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        //console.log(res, 'successfully submitted')
        this.setState({
          slugError: "",
          titleError: "",
          authorError: "",
          metadescriptionError: "",
          metakeywordsError: "",
        });
        toast("Blog Created Successfully !!");

        this.props.history.push("/blogs");
      })
      .catch(
        function (error) {
          // handle error
          console.log(error);
          if (error.response) {
            this.authorizationCheck(error.response.status);
          }

          if (error.response.data["author"]) {
            this.setState({
              authorError: error.response.data["author"],
            });
            toast.error(<div>Author: {this.state.authorError}</div>);
          } else {
            this.setState({
              authorError: "",
            });
          }
          if (error.response.data["slug"]) {
            this.setState({
              slugError: error.response.data["slug"],
            });
            toast.error(<div>Slug: {this.state.slugError}</div>);
          } else {
            this.setState({
              slugError: "",
            });
          }
          if (error.response.data["title"]) {
            this.setState({
              titleError: error.response.data["title"],
            });
            toast.error(<div>Title: {this.state.titleError}</div>);
          } else {
            this.setState({
              titleError: "",
            });
          }

          if (error.response.data["meta_description"]) {
            this.setState({
              metadescriptionError: error.response.data["meta_description"],
            });
            toast.error(<div>Meta Description: {this.state.metadescriptionError}</div>);
          } else {
            this.setState({
              metadescriptionError: "",
            });
          }

          if (error.response.data["meta_keywords"]) {
            this.setState({
              metakeywordsError: error.response.data["meta_keywords"],
            });
            toast.error(<div>Meta Keywords: {this.state.metakeywordsError}</div>);
          } else {
            this.setState({
              metakeywordsError: "",
            });
          }
          // else {
          //     toast.dark("Something unexpected occurs, Please try Again");
          // }
        }.bind(this)
      );
  }

  componentDidMount() {
    const token = localStorage.getItem("token");
    axios
      .get(process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/channels", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // console.log(res.data)
        this.setState({ channel: res.data.data });
        this.setState({ selectedChannel: res.data[0].id });
        //console.log("channels", res.data[0].id)
      })
      .catch(
        function (error) {
          // handle error
          if (error.response) {
            this.authorizationCheck(error.response.status);
          }
        }.bind(this)
      );
  }

  authorizationCheck(status) {
    if (status === 401) {
      axios
        .post(
          process.env.REACT_APP_AUTH_BASE_URL + "/api/v1/auth/refresh-token/",
          {
            refresh_token: localStorage.getItem("refresh_token"),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //console.log(res.data.data.access_token)
          localStorage.setItem("token", res.data.data.access_token);
          localStorage.setItem("refresh_token", res.data.data.refresh_token);
        })
        .catch(
          function (error) {
            // handle error
            localStorage.clear();
            this.props.history.push("/");
          }.bind(this)
        );
    }
  }

  handleDropdownChange = ({ target }) => {
    this.setState({
      selectedChannel: target.value,
    });
  };

  handleFeaturedImageChange = async (event) => {
    async function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
    let image = await getBase64(event.target.files[0]);
    // console.log(image);

    const token = localStorage.getItem("token");
    let res = await axios.post(
      "https://transmitter.evidentbd.com/api/v1/upload/image",
      {
        service: "evident-blog",
        encoded_image: image,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
          // 'Content-Type': 'application/json'
        },
      }
    );

    const image_url = res.data.data.edge_url;

    await this.setState({
      featuredImage: image_url,
    });
  };

  async uploadImageCallBack(file) {
    async function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
    let image = await getBase64(file);

    return new Promise(async (resolve, reject) => {
      const token = localStorage.getItem("token");

      //let response = null;

      let image_response = await axios.post(
        "https://transmitter.evidentbd.com/api/v1/upload/image",
        {
          service: "evident-blog",
          encoded_image: image,
        },
        {
          headers: {
            "secret-key": "GYGWYERY58454FDS4FD8V487FF8WQ8EF11D88W1D",
          },
        }
      );

      const image_url = image_response.data.data.edge_url;
      console.log(image_url);
      resolve({ data: { link: image_url } });
    });
  }

  onFormFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (
        this.state.title !== "" &&
        this.state.slug !== "" &&
        this.state.metaDescription !== "" &&
        this.state.metaKeywords !== "" &&
        this.state.tags !== "" &&
        this.state.authorname !== "" &&
        this.state.startDate !== "" &&
        this.state.selectedChannel !== ""
      )
        this.setState({ disable: false });
      else this.setState({ disable: true });
    });
  };

  render() {
    return (
      <div className="container mt-3 mb-6">
        <div className="grid lg:grid-cols-2 gap-4 mt-4">
          <nav className="bg-grey-light rounded font-sans w-full pl-1">
            <ol className="list-reset flex text-gray-500">
              <li>
                <Link to="/dashboard">
                  <span className="hover:text-gray-800">Dashboard</span>
                </Link>
              </li>
              <span className="mx-2">/</span>
              <li>
                <Link to="/blogs">
                  <span className="hover:text-gray-800">Blogs</span>
                </Link>
              </li>
              <span className="mx-2">/</span>
              <li>Create Blog</li>
            </ol>
          </nav>
        </div>

        <h1 className="text-xl font-bold leading-tight flex items-center mb-5 pl-1 mt-5">
          Create Blog
        </h1>

        <div className="h-3/4 container editor bg-white p-5 rounded rounded-md">
          <div className="grid lg:grid-cols-2 gap-4 mt-4 mb-4">
            <div className="w-full">
              <input
                title="Title of the Blog"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Title of the Blog"
                // value={searchTerm}
                // onChange={handleChange}
                name="title"
                onChange={this.onFormFieldChange}
              />
              {this.state.titleError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.titleError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>

            <div className="w-full">
              <input
                title="Slug"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Slug"
                maxLength="50"
                // value={searchTerm}
                // onChange={handleChange}
                name="slug"
                onChange={this.onFormFieldChange}
              />
              {this.state.slugError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.slugError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>
          </div>

          <div className="grid lg:grid-cols-3 gap-4 mt-4 mb-4">
            <div className="w-full">
              <input
                title="Meta Description"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Meta Description"
                maxLength="155"
                // value={searchTerm}
                // onChange={handleChange}
                name="metaDescription"
                onChange={this.onFormFieldChange}
              />
              {this.state.metadescriptionError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.metadescriptionError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>

            <div className="w-full">
              <input
                title="Meta Keywords"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Meta Keywords"
                maxLength="100"
                name="metaKeywords"
                // value={searchTerm}
                // onChange={handleChange}
                onChange={this.onFormFieldChange}
              />
              {this.state.metakeywordsError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.metakeywordsError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>

            <div className="w-full">
              <input
                title="Input Tags"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Input Tags"
                maxLength="100"
                value={this.state.tags}
                name="tags"
                onChange={this.onFormFieldChange}
              />
            </div>
          </div>

          <div className="grid lg:grid-cols-4 gap-4 mt-4 mb-4">
            <div className="w-full">
              <input
                title="Author's Name"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Author's Name"
                name="authorname"
                onChange={this.onFormFieldChange}
              />
              {this.state.authorError !== "" ? (
                <label className="text-red-500 text-sm">{this.state.authorError}</label>
              ) : (
                <label className="text-red-500 text-sm"></label>
              )}
            </div>

            <div className="z-50 grid lg:grid-cols-1">
              <DatePicker
                title="Publish Date"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                selected={this.state.startDate}
                onChange={this.onFormFieldChange}
                name="startDate"
                isClearable
                placeholderText="Publish Date"
              />
            </div>

            <div className="w-full">
              <select
                title="Select Channel"
                className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                type="button"
                value={this.state.selectedChannel}
                name="selectedChannel"
                onChange={this.onFormFieldChange}
              >
                <option className="py-1" value="" disabled>
                  Select Channel
                </option>
                {/* <option className="py-1" value="all">Select Channel</option> */}
                {this.state.channel.map(({ name, id }, index) => (
                  <option className="py-1" value={id} key={id}>
                    {name}
                  </option>
                ))}
                {/* {this.state.channel.map(({ name, id }, index) => <option className="py-1" value={id} >{name}</option>)} */}
              </select>
              <label className="text-red-500 text-sm"></label>
            </div>

            <div className="w-full">
              <input
                type="file"
                title="Feature Image"
                className="w-full bg-white py-2 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                placeholder="Feature Image"
                name="featuredImage"
                onChange={this.handleFeaturedImageChange}
              />
            </div>
          </div>

          <div className="grid lg:grid-cols-1 gap-4 mt-4 mb-4">
            {/* quill editor */}
            <div className="text-editor" style={{}}>
              <EditorToolbar />
              <ReactQuill
                style={{ height: "80vh" }}
                theme="snow"
                value={this.state.qState}
                onChange={this.handleChange}
                placeholder={"Write something awesome..."}
                modules={modules}
                formats={formats}
              />
            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 gap-4 mt-4 mb-4">
          <div className="z-50 px-5 py-2">
            <label className="text-gray-700 text-sm">Schedule Date: </label>
            <DatePicker
              type="button"
              title="Select Schedule Date"
              className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
              selected={this.state.schedule_date}
              onChange={(date) => this.setState({ schedule_date: date })}
              isClearable
              placeholderText="Schedule Publish Date"
            />
          </div>

          <div className="px-5 py-2 flex justify-end">
            <button
              disabled={this.state.disable}
              type="submit"
              className={
                this.state.disable
                  ? "bg-gray-400 opacity-50 text-white font-bold py-2 px-24 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  : "bg-indigo-600 hover:bg-indigo-800 text-white font-bold py-2 px-24 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              }
              onClick={() => {
                this.handleSubmit();
              }}
            >
              Publish
            </button>
          </div>
        </div>

        <div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}
